import axios from 'axios';
import { getToken, setToken, removeToken } from '../utils/secureStorage';
import { isTokenExpired } from '../utils/jwt';

// const baseURL = 'http://localhost:8080/';
const baseURL = 'https://countdown.webhizzy.in/';

const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  async (config) => {
    let token = getToken('accessToken');
    if (token && isTokenExpired(token)) {
      const refreshToken = getToken('refreshToken');
      if (refreshToken && !isTokenExpired(refreshToken)) {
        try {
          const response = await axios.post(`${baseURL}/token`, { refreshToken });
          if (!response.data.error) {
            token = response.data.accessToken;
            setToken('accessToken', token);
          } else {
            removeToken('accessToken');
            removeToken('refreshToken');
            window.location.href = '/countdown';
          }
        } catch (error) {
          console.error('Failed to refresh token', error);
          removeToken('accessToken');
          removeToken('refreshToken');
          window.location.href = '/countdown';
        }
      } else {
        removeToken('accessToken');
        removeToken('refreshToken');
        window.location.href = '/countdown';
      }
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.data.error) {
      console.log(response.data);
      if (response.data.message.includes('Invalid token') || response.data.message.includes('No token provided')) {
        removeToken('accessToken');
        removeToken('refreshToken');
        window.location.href = '/countdown';
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
